<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="10">
                <form-tanggal @submit="submit"></form-tanggal>
                <b-card v-if="profitLossContent">
                    <div class="d-flex justify-content-end mb-2">
                        <b-button class="text-right" variant="outline-primary" :disabled="!this.profitLossContent" @click.prevent="print">Download</b-button>
                    </div>
                    <section id="table-content" v-html="profitLossContent"></section>
                </b-card>
                <div class="alert alert-danger p-1" v-else>
                    Harap pilih tanggal awal & tanggal akhir
                </div>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput} from 'bootstrap-vue'
import FormTanggal from './components/FormTanggal'
export default {
    components: {
        FormTanggal,
        BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput
    },
    computed: {
        // netProfit() {
        //     if(!this.profitAndLoss) {
        //         return 0
        //     }
        //     const totalPendapatan = this.profitAndLoss.pendapatan ? this.getTotalPendapatan( this.profitAndLoss.pendapatan ) : 0
        //     const totalPengeluaran = this.profitAndLoss.pengeluaran ? this.getTotalPengeluaran( this.profitAndLoss.pengeluaran ) : 0

        //     return totalPendapatan - totalPengeluaran
        // }
    },
    data: () => ({
        loading: false,
        form: {
            tgl_awal: null,
            tgl_akhir: null
        },
        profitAndLoss: null,
        profitLossContent: null
    }),
    methods: {
        async print() {
            if(!this.profitLossContent) {
                this.displayError({
                    message: 'Harap klik lihat laporan terlebih dahulu sebelum download'
                })
                return false
            }

            const params = Object.assign({}, this.form)
            params.url = this.$route.meta.api

            try {
                this.loading = true
                const response = await this.$store.dispatch('laporan/getData', params)
                window.open(URL.createObjectURL(response))
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async submit({tgl_awal, tgl_akhir}) {
            try {

                this.loading = true
                this.form.tgl_awal = tgl_awal
                this.form.tgl_akhir = tgl_akhir
                const params = { tgl_awal, tgl_akhir }
                this.profitLossContent = null
                const result = await this.$store.dispatch('laporan/getProfitAndLoss', params)
                let content = null
                content = `
                    <table border="1" id="profit-loss-table" class="table table-bordered">
                        <tr>
                            <th colspan="2"><h5>Periode</h5></th>
                            <th align="right">Dari ${this.form.tgl_awal} sampai dengan ${this.form.tgl_akhir}</th>
                        </tr>
                    `
                result.data.map(item => {
                    if(item.ketagori) {
                        content += `<tr>
                            <th colspan="3">${item.kategori ? item.kategori : null}</th>
                        </tr>    `
                    }
    
                    if(item.detail && item.detail.length > 0) {
                        item.detail.map(detail => {
                            content += `<tr>`
                            content += `<td>${detail.nomor ? detail.nomor : null}</td>`
                            content += `<td>${detail.akun ? detail.akun : null}</td>`
                            content += `<td align="right">${detail.total ? detail.total : null}</td>`
                            content += `</tr>`
                        })
                    }
    
                    content += `<tr>
                        <th colspan="2" align="left">${item.keterangan ? item.keterangan : null}</th>
                        <th class="text-right" align="right">${item.total ? item.total : null}</ class="text-right">
                    </tr>`
                })
    
                // Pajak
                if(result.info) {
                    content += `<tr>
                        <th colspan="2">Laba/Rugi Bersih Sebelum Pajak</th>
                        <th class="text-right">${result.info.totalTanpaPajak}</th>
                    </tr>`
                    if(result.info.pajak) {
                            content += `<tr>
                            <td>${result.info.pajak.nomor}</th>
                            <td>${result.info.pajak.kategori}</th>
                                <td class="text-right">${result.info.totalPajak}</th>
                            </tr>`
                    }
    
                    content += `<tr>
                    <th colspan="2">Laba/Rugi Bersih Setelah Pajak</th>
                    <th class="text-right">${result.info.totalDenganPajak}</th>
                    </tr>`
                }
                this.profitLossContent = content
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    }
}
</script>
<style>
    #profit-loss-table tr:nth-child(even) {
        background-color: #bedbff;
    }

    #profit-loss-table, #profit-loss-table th, #profit-loss-table td  {
        border: 1px solid #333;
    }
</style>